import request from "./request";
import baseUrl from "./baseUrl";

// this is for globally used APIs only

export default {
  login: (params = {}) => request.post(`${baseUrl.main}/ba021/v1/org/login`, params),
  getTopicList: (params = {}) => request.post(baseUrl.main + "/as/index/recommend/v1", params),
  imageUpload: (params = {}) => request.post("http://staging-api.tranzak.me/cb068/v1/file/upload/admin/image", params)
}
