<template>
    <div class="main">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="row p-1 pt-0">
                            <div class="col-12 pb-1">
                                <span class="font-weight-bolder">{{t('filters')}}</span>
                            </div>
                            <div class="col-lg-3 col-12 col-md-6">
                                <label>{{t('dateRange')}}</label>
                                <flat-pickr
                                    v-model="range"
                                    class="form-control text-center"
                                    :config="{ mode: 'range'}"
                                    />
                            </div>
                            <div class="col-lg-4 col-12 col-md-6">
                                <label>{{t('status')}}</label>
                                <v-select  v-model="searchStatus"  :options="statusOptions" :get-option-label="(option) => t(option.label)"></v-select>
                            </div>
                            <div class="col-lg-3 col-12 col-md-6">
                                <label>{{t('phone')}}</label>
                                <input v-model="searchPhone" type="text" class="form-control" placeholder="671234567">
                            </div>
                            <div class="col-lg-2 col-12 col-md-6 pt-2 pt-lg-2">
                                <b-button variant="primary" @click="filterMembers()">
                                    <feather-icon icon="SearchIcon" /> {{t('search')}}
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="col-12">
                    <div class="card">
                        <div class="p-1">
                            <h2>{{t('staff')}}</h2>
                        </div>
                        <div class="pb-1">
                            <b-button variant="primary" v-b-modal.find-user class="ml-1 mr-1">
                               <feather-icon icon="UserPlusIcon" /> {{t('addMember')}}
                            </b-button>
                        </div>
                        <b-modal no-close-on-backdrop centered id="find-user" no-stacking @ok="findUser" ok-only :ok-title="t('findUser')" :title="t('findTeamMember')" ok-variant="primary">
                            <b-overlay :show="usersBusy" class="p-1 p-lg-2">
                                <b-row>
                                    <b-col cols="12">
                                        <b-form-group :label="t('enterUsersId')">
                                            <b-form-input type="text" v-model="tzUID" :state="tzUIDStatus" autofocus autocomplete="off" />
                                                <small class="text-danger" v-if="tzUIDStatus === false">
                                                    {{t('usersIdValidation')}}
                                                </small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-overlay>
                        </b-modal>
                        <b-modal size="lg" no-close-on-esc no-close-on-backdrop @cancel="resetData" @ok="addMember" :title="t('addATeamMember')" ok-variant="primary" id="add-member" centered :ok-title="t('sendInvitation')" :ok-disabled="!canSubmit()">
                            <b-overlay :show="usersBusy" class="p-1 p-lg-2">
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="t('firstName')">
                                            <b-input v-model="fn" :state="!!fn" autocomplete="false" autofocus>

                                            </b-input>
                                            <small v-if="!fn" class="text-danger">
                                                {{t('required')}}
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="t('lastName')">
                                            <b-input v-model="ln" :state="!!ln">

                                            </b-input>
                                            <small v-if="!ln" class="text-danger">
                                                {{t('required')}}
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <label>{{t('enterMobileNumber')}}</label>
                                        <b-input-group>
                                            <template #append>
                                                <b-input-group-text>
                                                    <img :src="logo" height="24" width="30" />
                                                </b-input-group-text>
                                            </template>
                                            <b-form-input :state="isCameroonNumber" type="number" placeholder="671727374" v-model="phone"></b-form-input>
                                        </b-input-group>
                                        <template>
                                            <small v-if="!isCameroonNumber" class="text-danger">
                                                {{t('enterAValidCameroonNumber')}}
                                            </small>
                                        </template>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="t('email')">
                                            <b-input v-model="email" type="email" :state="email.length > 0? validateEmail(email): null">

                                            </b-input>
                                            <small v-if="!email" class="text-danger">
                                                {{t('optional')}}
                                            </small>
                                            <small v-else-if="email.length>0 && !validateEmail(email)" class="text-danger">
                                                {{t('enterValidEmail')}}
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="t('memberRole')">
                                            <v-select  v-model="role"  :options="roles" :get-option-label="(option) => t(option.label)"></v-select>
                                            <small v-if="!role || !role.value" class="text-danger">
                                                {{t('roleRequired')}}
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="t('sex')">
                                            <v-select  v-model="sex"  :options="sexes" :get-option-label="(option) => t(option.label)"></v-select>
                                            <small v-if="!sex" class="text-danger">
                                                {{t('optional')}}
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="t('address')">
                                            <b-input v-model="address" :state="address.length > 0 ? !!address: null">

                                            </b-input>
                                            <small v-if="!address" class="text-danger">
                                                {{t('optional')}}
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="t('remark')">
                                            <b-input v-model="remark" :state="remark.length > 0 ? !!remark: null">

                                            </b-input>
                                            <small v-if="!remark" class="text-danger">
                                                {{t('optional')}}
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-form-file id="avatar" class="d-none" @change="sendPhoto" accept=".png, .jpg, .jpeg">
                                        </b-form-file>
                                        <b-form-group :label="t('photo')">
                                            <div class="d-flex">
                                                <div>
                                                    <span @click="triggerUpload" class="bg-secondary rounded hover-reduce">
                                                        <b-avatar size="60px" :variant="!!avatar?'secondary' : 'primary'" class="cursor-pointer border-1 border-primary" :src="!!avatar? avatar : ''">
                                                            <feather-icon icon="UserPlusIcon" size="30" v-if="!avatar" />
                                                        </b-avatar>
                                                    </span>
                                                    <feather-icon icon="LoaderIcon" size="30" class="spinner" v-if="uploading" />
                                                    <span class="ml-1 alert-danger rounded" v-if="!!avatar">
                                                        {{t('deleteImage')}} <b-badge variant="danger" class="cursor-pointer rounded-circle" @click="resetAvatar">X</b-badge>
                                                    </span>
                                                </div>
                                            </div>
                                            <small v-if="!avatar" class="text-danger">
                                                {{t('optional')}}
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-overlay>
                        </b-modal>
                        <b-modal size="lg" no-close-on-esc no-close-on-backdrop @cancel="resetData" @close="resetData" @ok="updateMember" :title="t('updating') + ' ' + updateName" ok-variant="primary" id="update-member" centered :ok-title="t('updateMember')" :ok-disabled="!canSubmit()">
                            <b-overlay :show="usersBusy" class="p-1 p-lg-2">
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="t('firstName')">
                                            <b-input v-model="fn" :state="!!fn" autocomplete="false" autofocus>

                                            </b-input>
                                            <small v-if="!fn" class="text-danger">
                                                {{t('required')}}
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="t('lastName')">
                                            <b-input v-model="ln" :state="!!ln">

                                            </b-input>
                                            <small v-if="!ln" class="text-danger">
                                                {{t('required')}}
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <label>{{t('enterMobileNumber')}}</label>
                                        <b-input-group>
                                            <template #append>
                                                <b-input-group-text>
                                                    <img :src="logo" height="24" width="30" />
                                                </b-input-group-text>
                                            </template>
                                            <b-form-input :state="isCameroonNumber" type="number" placeholder="671727374" v-model="phone"></b-form-input>
                                        </b-input-group>
                                        <template>
                                            <small v-if="!isCameroonNumber" class="text-danger">
                                                {{t('enterAValidCameroonNumber')}}
                                            </small>
                                        </template>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="t('email')">
                                            <b-input v-model="email" type="email" :state="email.length > 0? validateEmail(email): null">

                                            </b-input>
                                            <small v-if="!email" class="text-danger">
                                                {{t('optional')}}
                                            </small>
                                            <small v-else-if="email.length>0 && !validateEmail(email)" class="text-danger">
                                                {{t('enterValidEmail')}}
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="t('memberRole')">
                                            <v-select  v-model="role"  :options="roles" :get-option-label="(option) => t(option.label)"></v-select>
                                            <small v-if="!role || !role.value" class="text-danger">
                                                {{t('roleRequired')}}
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="t('sex')">
                                            <v-select  v-model="sex"  :options="sexes" :get-option-label="(option) => t(option.label)"></v-select>
                                            <small v-if="!sex" class="text-danger">
                                                {{t('optional')}}
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="t('address')">
                                            <b-input v-model="address" :state="address.length > 0 ? !!address: null">

                                            </b-input>
                                            <small v-if="!address" class="text-danger">
                                                {{t('optional')}}
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="t('remark')">
                                            <b-input v-model="remark" :state="remark.length > 0 ? !!remark: null">

                                            </b-input>
                                            <small v-if="!remark" class="text-danger">
                                                {{t('optional')}}
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-form-file id="avatar" class="d-none" @change="sendPhoto" accept=".png, .jpg, .jpeg">
                                        </b-form-file>
                                        <b-form-group :label="t('photo')">
                                            <div class="d-flex">
                                                <div>
                                                    <span @click="triggerUpload" class="bg-secondary rounded hover-reduce">
                                                        <b-avatar size="60px" :variant="!!avatar?'secondary' : 'primary'" class="cursor-pointer border-1 border-primary" :src="!!avatar? avatar : ''">
                                                            <feather-icon icon="UserPlusIcon" size="30" v-if="!avatar" />
                                                        </b-avatar>
                                                    </span>
                                                    <feather-icon icon="LoaderIcon" size="30" class="spinner" v-if="uploading" />
                                                    <span class="ml-1 alert-danger rounded" v-if="!!avatar">
                                                        {{t('deleteImage')}} <b-badge variant="danger" class="cursor-pointer rounded-circle" @click="resetAvatar">X</b-badge>
                                                    </span>
                                                </div>
                                            </div>
                                            <small v-if="!avatar" class="text-danger">
                                                {{t('optional')}}
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-overlay>
                        </b-modal>
                        <b-overlay :show="itemsBusy">
                            <b-table
                            id="users-table"
                            :per-page="perPage"
                            :current-page="1"
                            responsive
                            :items="items"
                            :fields="fields"
                            >

                                <!-- <template #table-busy>
                                    <div class="text-center text-primary my-2">
                                        <b-spinner class="align-middle mr-1"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template> -->
                                <template #head()="scope">
                                    <div class="text-nowrap text-primary">
                                    {{ t(scope.column) }}
                                    </div>
                                </template>

                                <template #cell(status)="data">
                                    <b-badge :variant="` ${status[1][data.value]}`" v-if="data.item.userId">
                                        {{t(status[0][data.value])}}
                                    </b-badge>
                                </template>

                                <template #cell(role)="data">
                                    <span class="text-nowrap" v-if="data.item.userId">
                                        {{t(getRole(data.value))}}
                                    </span>
                                </template>


                                <template #cell(createdAt)="data">
                                    <span class="text-nowrap" v-if="data.item.userId">
                                        {{formatDate(data.value)}}
                                    </span>
                                    <span class="d-block text-nowrap pt-2" v-else>

                                    </span>
                                </template>




                                <template #cell(name)="data">
                                    <div class="clearfix"  v-if="data.item.firstName">

                                        <div class="d-flex" align-v="center">
                                            <div>
                                                <b-avatar :src="data.item.avatar? data.item.avatar: ''"  :text="!data.item.avatar? getAvatar(data.item.firstName,data.item.lastName): ''" variant="primary"></b-avatar>
                                            </div>
                                            <div class="w-100 pl-1">
                                                <div :class="`font-weight-bolder ${!data.item.phone? 'pt': ''}`">
                                                    {{data.item.firstName + ' ' + data.item.lastName }}
                                                </div>
                                                <a :href="`tel:+${data.item.phone}`">
                                                    <small class="text-primary" v-if="data.item.phone">{{data.item.phone}}</small>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <template #cell(actions)="data">
                                    <div v-if="data.item.userId">
                                    <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret v-if="data.item.status">
                                        <template #button-content>
                                            <feather-icon icon="MoreVerticalIcon"></feather-icon>
                                        </template>
                                        <b-dropdown-item @click="triggerEdit(data.item.userId)">
                                            <template>
                                                <span>
                                                    <feather-icon icon="EditIcon" /> {{t('editMember')}}
                                                </span>
                                            </template>
                                        </b-dropdown-item>
                                        <b-dropdown-item href="#" v-if="data.item.status != 2">
                                            <template>
                                                <span>
                                                    <feather-icon icon="SendIcon" /> {{t('resendInvitation')}}
                                                </span>
                                            </template>
                                        </b-dropdown-item>
                                        <b-dropdown-item href="#" @click="removeMember(data.item.userId)">
                                            <template>
                                                <span class="text-danger">
                                                    <feather-icon icon="Trash2Icon" /> {{t('removeMember')}}
                                                </span>
                                            </template>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                    </div>

                                </template>


                            </b-table>
                        </b-overlay>
                        <div class="d-flex p-1">
                            <div class="w-100">
                                {{t('page')}} <span class="text-primary"> {{currentPage}}</span> {{t('of')}} <span class="text-primary">{{Math.ceil(totalRows/perPage)}}</span>
                            </div>
                            <div class="w-100">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                    aria-controls="users-table"
                                    align="right"
                                    size="sm"
                                    class="my-0"
                                    @page-click="loadNewPage"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Utils from  '@/utils/index';
import { getUserData, isUserLoggedIn } from '@/auth/utils'
import commonApi from '@/services/api';
import Api from './api';

export default {
    name: 'Main',
    components:{
        vSelect,
        flatPickr
    },
    data() {
        return{
            regExp: /^\w+$/,
            tzUIDStatus: null,
            tzUID: '',
            userFound: false,
            fn: '',
            ln: '',
            phone: '',
            orgId: '',
            avatar: '',
            remark: '',
            email: '',
            sex: '',
            address: '',
            url: `${location.protocol}//${location.host}/`,
            logo: `${this.url}blank.png`,
            isCameroonNumber: false,
            uploading: false,
            usersBusy: false,
            itemsBusy: false,
            user: '',
            perPage: 10,
            totalRows: 0,
            currentPage: 1,
            range: '',
            searchDate: '',
            searchPhone: '',
            searchStatus: '',
            searchParams: {},
            userId: '',
            status: [{
                1: 'pending', 2: 'approved', 3: 'declined'
            },
            {
                1: 'alert-warning', 2: 'alert-success', 3: 'alert-danger'
            }],
            statusOptions: [
                {'label':'pending',"value": 1},
                {'label':'approved',"value": 2},
                {'label':'declined',"value": 3},
                {'label':'all', "value": "" },
            ],
            search: {},
            title: 'team',
            subTitle: 'merchant',
            subLink: '/apps/merchant',
            updateName: '',
            role:'',
            sexes: [
                {label: 'male', value: 1},
                {label: 'female', value: 2},
                {label: 'other', value: 3},
                {label: 'ratherNotSay', value: 0},
            ],
            roles: [
                {
                    label: 'nonStaff',
                    value: 100,
                },
                {
                    label: 'otherStaff',
                    value: 105,
                },
                {
                    label: 'itOrDeveloper',
                    value: 110,
                },
                {
                    label: 'auditor',
                    value: 115,
                },
                {
                    label: 'support',
                    value: 120,
                },
                {
                    label: 'analyst',
                    value: 125,
                },
                {
                    label: 'manager',
                    value: 130,
                },
                {
                    label: 'adminOrOwner',
                    value: 1000,
                },
            ],
            fields: [
                {key: 'name'},
                {key: 'role'},
                { key: 'status', label: 'Status' },
                {key: 'createdAt'},
                {key: 'actions'}
            ],
            items: [],
        }
    },
    computed: {
      rows() {
        return this.totalRows;
      }
    },
    watch:{
        phone: function(current, old) {
            if(parseInt(current)) {
                current = parseInt(current);
                if(this.isValidCmNumber(current)) {
                    this.isCameroonNumber = true;
                    this.submit = !this.amountCheck? true : false;
                }else{
                    this.submit = false;
                    this.isCameroonNumber = false;
                }
                this.logo = (this.isMtn(current))? this.url + 'mtn.jpg': (this.isOrange(current)? this.url + 'orange.jpg' : this.url + 'blank.png');
           }else{
               this.submit = false;
               this.numberCheck = true;
           }
        },
        amount: function(current, old){
            if(parseFloat(current) && current > 100){
                this.amountCheck = false;
                this.submit = true;
            }else{
                this.amountCheck = true;
                this.submit = false;
            }
        },
        range(newRange, oldRange) {
            newRange = newRange.split(' to ');
            if(newRange.length === 2) {
                this.searchDate = {
                    from: newRange[0],
                    to: newRange[1]
                }
            }else if(newRange[0].length == 10){
                 this.searchDate = {
                    from: newRange[0],
                    to: newRange[0]
                }
            }else {
                this.searchDate = '';
            }
        },
        items(current, old){
            this.items = current
        }
    },
    mounted() {
        this.getMembers();
        this.sex = this.sexes[0];
        this.role = this.roles[0];
        this.items = Utils.completeTable([]);
    },
    methods: {
        isValidCmNumber: function(phone) {
            return /^6[256789]\d{7}$/.test(phone);
        },
        isMtn: function(phone) {
            return /^6[78]/.test(phone) || /^65[0-4]/.test(phone);
        },
        isOrange: function(phone) {
            return /^69/.test(phone) || /^65[5-9]/.test(phone);
        },
        findUser(e){
            e.preventDefault();
            if(this.validTzUID()){
                // this.usersBusy = true;
                Api.getUserById({ufId: this.tzUID}).then(res=>{
                    this.usersBusy = false;
                    if(res.success && res.data){
                        if(res.data.userInfo){
                           const {
                               firstName, lastName, phone, avatar, email, sex, address
                           } = res.data.userInfo;
                           this.fn = firstName || '';
                           this.ln = lastName || '';
                           this.email = email || '';
                           this.avatar = avatar || '';
                           this.address = address || '';
                           this.phone = phone? phone.substr(4): '';
                           this.sex = sex || '';
                            this.tzUIDStatus = null;
                            this.$bvModal.show('add-member');
                            this.userFound = true;
                        }else{
                            this.showToast(this.$t("userNotFound"));
                        }
                    }
                }).catch(err=>{
                    this.usersBusy = false;
                    console.log(err);
                    this.showToast(this.$t("operationFailed"));
                })
            }
        },
        validTzUID(){
            if((this.tzUID.length >= 14 && this.tzUID.length <=22) && (this.regExp.test(this.tzUID))) {
                this.tzUIDStatus = true;
                return true;
            }

            this.tzUIDStatus =  false;
            return false;

        },
        updateMember(e){
            e.preventDefault();
            if(this.canSubmit()){
                var user = {
                    firstName: this.fn,
                    lastName: this.ln,
                    phone: this.phone,
                    role: this.role? this.role.value:  100,
                    avatar: this.avatar,
                    remark: this.remark,
                    email: this.email,
                    sex: this.sex? this.sex.value: 0,
                    address: this.address,
                    userId: this.userId,
                    orgId: JSON.parse(localStorage.getItem('userData')).orgId,
                }
                // this.usersBusy = true;
                Api.updateMember(user).then(res=>{
                    if(!res.success){
                        if(res.errorMsg.code == 11000){
                            this.showToast(this.$t('userPhoneExist'), 'danger', this.$t('error'));
                        }
                    }else{
                        this.items = this.items.map(item=>{
                            if(item.userId == this.userId){

                                item =  { ...res.data.user }

                                // item.firstName = user.firstName;
                                // item.lastName = user.lastName;
                                // item.phone = user.phone;
                                // item.role = user.role;
                                // item.avatar =  user.avatar;
                                // item.remark = user.remark;
                                // item.email = user.email;
                                // item.sex = user.sex;
                                // item.address = user.address;
                            }
                            return item;
                        })
                        this.resetData();
                        this.$bvModal.hide('update-member');
                    }
                    this.usersBusy = false;
                }).catch(err=>{
                    this.showToast(this.$t('userNotAdded'), 'danger', this.$t('error'));
                    console.log(err);
                    this.usersBusy = false;
                });
            }
        },
        triggerEdit(userId){
            this.userId = userId;
            this.items.map(m=>{
                if(m.userId == userId){
                    this.fn = m.firstName || '';
                    this.ln = m.lastName || '';
                    this.updateName = this.fn + ' ' + this.ln;
                    this.email = m.email || '';
                    this.avatar = m.avatar || '';
                    this.phone = m.phone.substr(4);
                    this.sex = this.sexes.filter(sex=> sex.value == m.sex)[0];
                    this.role =  this.roles.filter(role=> role.value == m.role)[0];
                    this.address = m.address || '';
                    this.remark = m.remark || '';
                }
                return m;
            });
            this.$bvModal.show('update-member')
        },
        formatDate(date){
            return Utils.formatDate(date);
        },
        getUniqueListBy(arr, key) {
            return [...new Map(arr.map(item => [item[key], item])).values()]
        },
        async getMembers(pageNum = 1){
            // this.itemsBusy = true;
            Api.getMembers({searchParams: this.searchParams, pageNum: pageNum}).then(res=>{
                if(res.success && res.data){
                    this.items = Utils.completeTable(res.data.list);
                    this.totalRows = res.data.total;
                    this.itemsBusy = false;
                }
            }).catch(err=>{
                this.itemsBusy = false;
                console.log('Error: ',err);
            })
        },
        loadNewPage(e,page){
            e.preventDefault();
            this.getMembers(page);
            this.currentPage = page;
        },
        removeMember(userId){
            this.$bvModal.msgBoxConfirm(this.$t('confirmRemoveMember') + '?', {
                title: this.$t('confirm'),
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                noCloseOnEsc: true,
                noCloseOnBackdrop: true
            })
            .then(value => {
                if(value){
                    this.itemsBusy = true;
                    Api.removeMember(userId).then(res=>{
                        if(res.success){
                            this.items = this.items.filter(item=> item.userId != userId );
                            this.itemsBusy = false;

                        }
                    }).catch(err=>{
                        console.log(err);
                    });
                }
            })
            .catch(err => {
                // An error occurred
            })
        },
        getAvatar(fn,ln){
            return fn[0] + ln[0];
        },
        getRole(role){
            let matched = this.roles.filter(r=> r.value == role );
            return matched[0].label;
        },
        canSubmit(){
            this.fn = this.fn.trim() || '';
            this.ln = this.ln.trim() || '';
            this.email = this.email.trim() || '';
            this.address = this.address.trim() || '';
            this.remark = this.remark.trim() || '';
            if(this.fn && !this.uploading && this.ln && this.isValidCmNumber(this.phone) && this.role && (this.email? this.validateEmail(this.email): true)){
                return true;
            }
            return false;
        },
        resetData(){
            this.fn = '';
            this.ln = '';
            this.phone = '';
            this.email = '';
            this.role = this.roles[0];
            this.sex = this.sexes[0];
            this.address = '';
            this.remark = '';
            this.avatar = '';
            this.isCameroonNumber = false;
            this.logo = "https://erudef.org/wp-content/uploads/2019/10/placeholder-300x200.png";
        },
        resetAvatar(){
            this.avatar ='';
            document.getElementById('avatar').value = '';
        },
        triggerUpload(){
            document.getElementById('avatar').click();
        },
        sendPhoto: function(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length){
                return;
            }
            if(files[0].type !== 'image/jpeg' && files[0].type !== 'image/jpg' && files[0].type !== 'image/png') {
              this.showToast(this.$t('fileMustBeImage')+' (.png / .jpg / .jpeg)', 'danger', this.$t('fileNotSupported'));
              return;
            }
            if((files[0].size / (1024 ** 2)) > 3){
              this.showToast(this.$t("fileGreaterThan")+ ' 3M');
              return;
            }

            // var formData = new FormData();
            // formData.append("files", files[0]);
            this.uploading = true;
            commonApi.imageUpload({files: files}).then(res=>{
                this.avatar = res.data.path[0];
                this.uploading = false;
                //document.getElementById('avatar').value = '';
            }).catch(er=>{
                this.resetAvatar();
                this.uploading = false;
                this.showToast(this.$t("unableToUploadPhoto"));
            })

            // this.newPhoto = `${this.logoDir}${this.logoList[this.logoIndex % (this.logoList.length-1)]}`;
            // this.logoIndex++;
        },
        validateEmail(inputText){
            var mailformat =  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if(inputText.match(mailformat)){
                return true;
            }
            return false;
        },
        addMember(e){
            e.preventDefault();
            if(this.canSubmit()){
                var user = {
                    firstName: this.fn,
                    lastName: this.ln,
                    phone: this.phone,
                    role: this.role? this.role.value:  100,
                    scopeId: getUserData().scopeId,
                    orgId: getUserData().orgId,
                    avatar: this.avatar,
                    remark: this.remark,
                    email: this.email,
                    sex: this.sex? this.sex.value: 0,
                    address: this.address,
                    userId: this.tzUID
                }
                // this.usersBusy = true;
                Api.addMember(user).then(res=>{
                    if(!res.success){
                        if(res.errorMsg.code == 11000){
                            this.showToast(this.$t('userAlreadyExist'), 'danger', this.$t('error'));
                        }else{
                            this.showToast(this.$t('userNotAdded'), 'danger', this.$t('error'));
                        }
                    }else{
                        if(this.items[this.items.length-1].orgId == undefined){
                            this.items.pop();
                        }
                        this.items.unshift(res.data.user);
                        if(this.totalRows < this.items.length){
                            this.totalRows = this.items.length;
                        }
                        this.userFound = false;
                        this.resetData();
                        this.$bvModal.hide('add-member');
                    }
                    this.usersBusy = false;
                }).catch(err=>{
                    this.showToast(this.$t('userNotAdded'), 'danger', this.$t('error'));
                    console.log(err);
                    this.usersBusy = false;
                });
            }
        },
        m(money){
            return money.toLocaleString();
        },
        filterMembers() {
            if(!this.searchDate && !this.searchPhone && !this.searchStatus){
                this.searchParams = {};
            }else{
                this.searchParams = {};
                if(this.searchPhone){
                    this.searchParams.phone = this.searchPhone;
                }
                if(this.searchStatus && this.searchStatus.value){
                    this.searchParams.status = this.searchStatus.value;
                }
                if(this.searchDate){
                    this.searchParams.startDate = new Date(this.searchDate.from).toISOString();
                    if(!this.searchDate.to){
                        this.this.searchDate.to = this.searchDate.from;
                    }
                    this.searchParams.endDate = new Date(this.searchDate.to + ' 23:59:59').toISOString();
                }
            }
            this.getMembers({}, 1);
            this.currentPage = 1;
        },
        showToast(message,  variant = 'danger', title = null) {
            this.$bvToast.toast(`${message}`, {
            title: title || this.$t('error'),
            autoHideDelay: 5000,
            appendToast: true,
            solid: true,
            variant: variant
            })
        },
    },
    setup(props) {
    const { t } = useI18nUtils()

    return {
      // i18n
      t,
     }
  }
}
</script>
<style lang="scss" scope="">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    .hover-reduce:hover{
        opacity: .7;
    }
    .pt{
        padding-top: 7.5px !important;
    }
    .height-fill{
        height: 80%;
        min-height: 80%;
    }
    .color-light{
        color: #fff !important;
    }
    .txn-logos{
        margin-bottom: 3px;
        width: 40px;
        border-radius: 2px;
    }
</style>