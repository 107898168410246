import request from "@/services/request"
import baseUrl from "@/services/baseUrl"

let servicePath = "/ba021/v1/org/";
const url = baseUrl.main + servicePath;

// this is for globally used APIs only
export default {
  getUserById: (params = {}) => request.post(baseUrl.main + "/ba021/v1/admin/userById", params),
  getMembers: (params = {}) => request.post(url + "list", params),
  addMember: (params = {}) => request.post(url + "new", params),
  updateMember: (params = {}) => request.put(url + "user", params),
  removeMember: (params = null) => request.post(url + "delete/" + params, {}),
}